import React from "react"
import { Button, Container, Figure } from "react-bootstrap"
import Layout from "../components/Layout"
import { Seo } from "../components/Seo"
import { useLocation } from "@reach/router"

export default function NotFound() {
  const pathLocation = useLocation();
  const url = pathLocation.href;
  return (
    <Layout>
      <Seo title="404 Page" url={url}/>
    <div className="not-found"> 
    <Container>   
        <p className="title">Oops!</p>
        <p className="subtitle">We are resourceful, but we couldn't find the page you were looking for.</p>
        <Button href="/" className="secondary-btn-s"> Homepage
          <Figure className="read-more">
                  <Figure.Image   src={require('../assets/images/next_service.png')}  />
          </Figure>
      </Button>
      </Container>
    </div>
    </Layout>
  )
}   